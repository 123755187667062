import { version } from './version';
import { IEnvironment } from '@app/interfaces';

export const environment: IEnvironment = {
  assets: 'https://d2bkxke45bupbc.cloudfront.net/quiz/develop/assets',
  production: false,
  version,
  programURL: 'https://programs.stretchitlive.tech',
  baseURL: 'https://api-stage.stretchitapp.com',
  website: 'https://stage.stretchitapp.com',
  webapp: 'https://stage-web.stretchitapp.com',
  mixpanelToken: '758f1a6376c229b8f52a643c036659cd',
};
