import { Duration, ExperienceLevel, FlexibilityLevel, Gender } from '@app/dataset';

export type QuizAnswer = {
  stepId: string;
  value: QuizAnswerValue;
  label: string;
};

export type QuizAnswerValue =
  | string
  | number
  | boolean
  | boolean[]
  | string[]
  | number[]
  | Duration[]
  | ExperienceLevel
  | FlexibilityLevel
  | HeightInputAnswerValue
  | BirthdayInputAnswerValue
  | WeightInputAnswerValue;

export type HeightInputAnswerValue = {
  feet: string;
  inches: string;
  centimeters: string;
};
export function isHeightInputAnswerValue(value: QuizAnswerValue): value is HeightInputAnswerValue {
  if (typeof value !== 'object') {
    return false;
  }
  const v = value as HeightInputAnswerValue;
  return v.feet !== undefined || v.inches !== undefined || v.centimeters !== undefined;
}

export type WeightInputAnswerValue = {
  kilograms: string;
  pounds: string;
};
export function isWeightInputAnswerValue(value: QuizAnswerValue): value is WeightInputAnswerValue {
  if (typeof value !== 'object') {
    return false;
  }
  const v = value as WeightInputAnswerValue;
  return v.kilograms !== undefined || v.pounds !== undefined;
}

export type BirthdayInputAnswerValue = {
  day: string;
  month: string;
  year: string;
};
export function isBirthdayInputAnswerValue(
  value: QuizAnswerValue
): value is BirthdayInputAnswerValue {
  if (typeof value !== 'object') {
    return false;
  }
  const v = value as BirthdayInputAnswerValue;
  return v.day !== undefined || v.month !== undefined || v.year !== undefined;
}

export function isStringAnswerValue(value: QuizAnswerValue): value is string {
  return typeof value === 'string';
}
export function isNumberAnswerValue(value: QuizAnswerValue): value is number {
  return typeof value === 'number';
}

export function isStringArrayAnswerValue(value: QuizAnswerValue): value is string[] {
  return Array.isArray(value) && value.every(v => typeof v === 'string');
}
export function isNumberArrayAnswerValue(value: QuizAnswerValue): value is number[] {
  return Array.isArray(value) && value.every(v => typeof v === 'number');
}

export function isDurationAnswerValue(value: QuizAnswerValue): value is Duration[] {
  return isNumberArrayAnswerValue(value);
}
export function isExperienceLevelAnswerValue(value: QuizAnswerValue): value is ExperienceLevel {
  return isNumberAnswerValue(value);
}
export function isFlexibilityLevelAnswerValue(value: QuizAnswerValue): value is FlexibilityLevel {
  return isNumberAnswerValue(value);
}
export function isGenderAnswerValue(value: QuizAnswerValue): value is Gender {
  return isStringAnswerValue(value);
}
