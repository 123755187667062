import { Inject, Injectable } from '@angular/core';
import { ENVIROMENT } from '@app/tokens';
import { IEnvironment } from '@app/interfaces';

export type IConfiguration = IEnvironment;

@Injectable({ providedIn: 'root' })
export class AppConfiguration implements IConfiguration {
  readonly assets: string;
  readonly version: string;
  readonly production: boolean;
  readonly baseURL: string;
  readonly programURL: string;
  readonly webapp: string;
  readonly website: string;
  readonly mixpanelToken: string;

  constructor(@Inject(ENVIROMENT) env: IEnvironment) {
    this.assets = env.assets;
    this.version = env.version;
    this.production = env.production;
    this.baseURL = env.baseURL;
    this.webapp = env.webapp;
    this.website = env.website;
    this.programURL = env.programURL;
    this.mixpanelToken = env.mixpanelToken;
  }
}
