import { inject, Injectable } from '@angular/core';
import { Event, GuardsCheckEnd } from '@angular/router';
import { AppConfiguration } from '@app/services/configuration/app.configuration';
import mixpanel from 'mixpanel-browser';

declare let gtag: any;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private readonly appConfig = inject(AppConfiguration);
  private ready!: Promise<void>;

  constructor() {
    // init all analytics libraries
    this.ready = new Promise(resolve => {
      mixpanel.init(this.appConfig.mixpanelToken, {
        persistence: 'localStorage',
        track_pageview: true,
        debug: !this.appConfig.production,
      });
      resolve();
    });
  }

  async setupUser(userId: number) {
    // setup user
    await this.ready;

    gtag('config', 'G-C3PBFY5NL7', {
      user_id: `${userId}`,
    });
    mixpanel.identify(`${userId}`);
  }

  get mixpanelDistinctId() {
    return mixpanel.get_distinct_id();
  }

  async pageView(event: Event): Promise<void> {
    if (this.isBrokenLink(event)) return;

    await this.ready;
  }

  async quizStarted(name: string): Promise<void> {
    await this.ready;
    gtag('event', 'quiz_start', {
      name, //название квиза
    });
    mixpanel.track('quiz_started', { quiz_name: name });
  }

  async quizStepStarted(
    quiz_name: string,
    step_name: string,
    step_number: number,
    selected_option?: string
  ): Promise<void> {
    await this.ready;
    gtag('event', 'quiz', {
      name: quiz_name, //название квиза
      step: step_number, //шаг квиза (номер), экраны, которые просто с картинкой тоже учитываются
      selected_option, //опция, которую выбрали на ПРЕДЫДУЩЕМ шаге
      id: step_name, //id шага
    });
    mixpanel.track('quiz_step_started', {
      quiz_name,
      step_name,
      step_number,
    });
  }

  async quizCompleted(quiz_name: string): Promise<void> {
    await this.ready;
    // console.log('[Analytics] Quiz completed');
    mixpanel.track('quiz_completed', { quiz_name });
  }

  async quizStepCompleted(
    quiz_name: string,
    step_number: number,
    step_name: string,
    selected_option: string
  ): Promise<void> {
    await this.ready;
    // console.log('[Analytics] Quiz step completed', stepId, answer);
    mixpanel.track('quiz_step_completed', {
      quiz_name,
      step_name,
      step_number,
      selected_option,
    });
  }

  private isBrokenLink(event: Event): string | boolean | undefined {
    if (event instanceof GuardsCheckEnd) {
      return event && event.url && event.url.includes('sale%3F');
    }

    return undefined;
  }
}
